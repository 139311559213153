/* ==========================================================================
   Buttons
   ========================================================================== */
.btn--primary,
.btn--primary-white {
  position: relative;
  z-index: 2;
  transition: all 0.2s ease-in-out;
  border-width: 2px;
  border-radius: 25px;
  overflow: hidden;

  @include media($desktop-up) {
    &:hover,
    &:focus {
      &:after {
        width: 100%;
      }
    }

    &:after {
      content: '';
      position: absolute;
      left: 0px;
      top: 0px;
      bottom: 0px;
      width: 0%;
      z-index: -1;
      transition: all 350ms ease 30ms;
    }
  }
}

.btn--primary {
  border-color: $burnt-orange;
  
  &:after {
    background-color: $burnt-orange;
  }
}

.btn--primary-white {
  background-color: $burnt-orange;
  border-color: $cream;
  color: $cream;

  @include media($desktop-up) {
    &:hover,
    &:focus {
      color: $burnt-orange;
    }

    &:after {
      background-color: $cream;
    }
  }
}
