fieldset {
  background-color: lighten($base-border-color, 10%);
  border: $base-border;
  margin: 0 0 $small-spacing;
  padding: $base-spacing;
}

input,
label,
select {
  display: block;
  font-family: $base-font-family;
  font-size: $base-font-size;
}

label {
  font-weight: 600;
  margin-bottom: $small-spacing / 2;

  &.required::after {
    content: "*";
  }

  abbr {
    display: none;
  }
}

#{$text-inputs-list},
select[multiple=multiple],
textarea {
  background-color: $base-background-color;
  border: $base-border;
  border-radius: $base-border-radius;
  box-shadow: $form-box-shadow;
  box-sizing: border-box;
  font-family: $base-font-family;
  font-size: $base-font-size;
  margin-bottom: $base-spacing / 2;
  padding: $base-spacing / 3;
  transition: border-color;
  width: 100%;

  &:hover {
    border-color: darken($base-border-color, 10%);
  }

  &:focus {
    border-color: $action-color;
    outline: none;
  }
}

textarea {
  resize: vertical;
}

input[type="search"] {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}

input[type="checkbox"],
input[type="radio"] {
  display: inline;
  margin-right: $small-spacing / 2;
}

input[type="file"] {
  padding-bottom: $small-spacing;
  width: 100%;
}

select {
  margin-bottom: $base-spacing;
  max-width: 100%;
  width: auto;
  appearance: none;
  &::-ms-expand {
    display: none;
  }
}

input[type="radio"] {
  display: none;

  &:checked + label:before {
    box-shadow: 0 0 0 2px white inset, 1px 1px 0 8px currentcolor inset;
  }

  + label:before {
    content: '';
    display: inline-block;
    color: #444444;
    vertical-align: middle;
    width: 14px;
    height: 14px;
    margin: 0 5px 3px 0;
    border: 1px solid #cccccc;
    border-radius: 100%;
  }
}

.select-wrapper {
  position: relative;
  
  &:after {
    position: absolute;
    font-family: FontAwesome;
    content: '\f107';
    color: #999999;
    font-size: 15.4px;
    top: 10px;
    right: 10px;
  }

  select {
    background-color: #f7f7f7;
    width: 100%;
    height: 40px;
    border: 1px solid #dcdcdc;
    border-radius: 0;
    font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-size: 13px;
    padding: 0 20px 0 10px;
  }
}
