// -- Image Text -- //

$ns: '.image-text-component';

#{$ns} {
  position: relative;
  margin: 30px 0;

  @include media($tablet-up) {
    margin: 200px 0;
  }

  &.reverse {
    #{$ns}__floating-text {
      @include media($tablet-up) {
        left: 45%;
        text-align: left;
      }
    }

    #{$ns}__image {
      @include media($tablet-up) {
        order: 1;
      }
    }
  }

  &__floating-text {
    display: none;
    position: absolute;
    top: -57%;
    left: 55%;
    text-align: right;
    transform: translate(-50%, 50%);
    font-size: 120px;
    letter-spacing: 9.17px;
    width: 100%;
    max-width: 1400px;
    opacity: 0.1;
    font-family: $playfair;
    font-weight: bold;
    color: $burnt-orange;
    z-index: -1;

    @include media($tablet-up) {
      display: block;
    }
    @include media($desktop-up) {
      font-size: 180px;
    }

    @media screen and (min-width: 1400px) {
      font-size: 220px;
    }
  }

  &__wrapper {
    max-width: 1056px;
    margin: 0 auto;

    @include media($tablet-up) {
      display: flex;
    }
  }

  &__item {
    @include media($tablet-up) {
      width: 50%;
    }
    
    &--title {
      font-family: $playfair;
      font-weight: bold;
      font-size: 24px;
      line-height: 35px;
      margin-bottom: 25px;
    }

    &--content {
      line-height: 24px;
    }

    &--button {
      margin-top: 40px;
    }
  }

  &__image {
    margin-bottom: 30px;

    @include media($tablet-up) {
      margin-bottom: 0;
    }
    
    img {
      border-radius: 10px;
      width: 100%;
      box-shadow: 0 25px 60px 0 rgba(0,0,0,0.10);
    }
  }

  &__text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    @include media($tablet-up) {
      padding: 50px;
      text-align: left;
    }

    @include media($lg-desktop-up) {
      padding: 100px;
    }
  }

  &.us-beach {
    #{$ns} {
      &__item--content {
        font-family: $playfair;
        font-weight: bold;
        font-size: 24px;
        line-height: 40px;
      }
    }
  }
}