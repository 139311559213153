// -- Header -- //

$ns: '.header-component';

#{$ns} {
  position: absolute;
  z-index: 9999;
  height: 0;
  width: 100%;

  &.nav-open {
    #{$ns} {
      &__button {
        color: $light-cream;

        span {
          width: 100%;
        }
      }

      &__wrapper {
        width: 100%;
      }

      &__background {
        transform: scale(80);
      }

      &__link {
        transition: all 0.5s ease-in-out;
        opacity: 1;
        transform: translateY(0);
        transition-delay: 0.6s;

        &:last-child {
          transition-delay: 0.7s;
        }
      }

      &__floating-image {
        opacity: 1;
        transition-delay: 0.75s;
      }
    }
  }

  &__button {
    position: absolute;
    top: 1rem;
    left: 2rem;
    z-index: 10;
    font-family: $playfair;
    font-weight: bold;
    color: $cream;
    font-size: 24px;
    line-height: 90px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    transition: color 0.5s ease-in-out;

    @include media($desktop-up) {
      top: 2.25rem;
      left: 2.5rem;
    }

    &.light {
      color: $cream;
    }

    &.dark {
      color: $burnt-orange;
    }

    span {
      width: 0;
      overflow: hidden;
      transition: width 0.5s ease-in-out;
    }
  }

  &__wrapper {
    position: relative;
    height: 100vh;
    width: 0;
    transition: width 0.75s ease-in-out;
    display: flex;
    align-items: center;
    padding-left: 30px;
    overflow: hidden;

    @include media($desktop-up) {
      padding-left: 0;
      justify-content: center;
    }
  }

  &__background {
    width: 6rem;
    height: 6rem;
    position: absolute;
    border-radius: 50%;
    top: 1rem;
    left: 1.25rem;
    background-color: #F0A659;
    z-index: 9;
    transition: transform .8s cubic-bezier(0.86, 0, 0.07, 1);

    @include media($desktop-up) {
      top: 2.25rem;
      left: 1.75rem;
    }
  }

  &__links {
    z-index: 11;
    @include media($desktop-up) {
      margin-right: 150px;
    }
  }

  &__link {
    font-family: $playfair;
    font-weight: bold;
    font-size: 60px;
    line-height: 80px;
    position: relative;
    opacity: 0;
    transform: translateY(25px);
    transition: opacity 0.5s ease-in-out, transform 1s ease-in-out;

    @include media($desktop-up) {
      &:hover {
        &:after {
          width: 100%;
        }
      }
    }

    &:not(:last-child) {
      margin-bottom: 75px;
    }

    &:after {
      content: '';
      width: 0;
      position: absolute;
      bottom: -25px;
      left: 0;
      background-color: $light-cream;
      height: 8px;
      transition: width 0.5s ease-in-out;
    }

    a {
      color: $light-cream;
    }
  }

  &__floating-image {
    position: absolute;
    top: 25px;
    right: -100px;
    transform: rotate(180deg);
    max-width: 290px;
    z-index: 11;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;

    @include media($tablet-up) {
      right: 25px;
    }

    @include media($desktop-up) {
      max-width: inherit;
      top: 75px;
    }

    svg {
      width: 100%;
    }
  }
}