// -- RSVP -- //

$ns: '.rsvp-component';

#{$ns} {
  margin: 60px auto;
  width: 90%;

  @include media($tablet-up) {
    margin: 120px auto;
  }

  label {
    font-size: 14px;
    font-weight: 200;
    color: $brown;
    text-transform: uppercase;
    margin-bottom: 10px;
  }

  &__wrapper {
    position: relative;
  }

  &__form {

    &--container {
      box-shadow: 0 13px 35px rgba(0, 0, 0, 0.1 );
      padding: 60px;

      @include media($desktop-up) {
        padding: 120px;
        width: 76%;
      }
    }

    &--wrapper {
      max-width: 315px;
      @include media($lg-desktop-up) {
        width: 48%;
      }
    }

    &-input-wrapper,
    &-radio-wrapper,
    &-select-wrapper {
      margin-bottom: 30px;
    }

    &-title {
      font-family: "Playfair Display", serif;
      font-weight: bold;
      font-size: 24px;
      line-height: 35px;
      margin-bottom: 20px;
    }

    &-text {
      line-height: 24px;
      margin-bottom: 30px;
    }

    &--response {
      p:first-child {
        font-size: 24px;
      }
    }

  }

  &__text {
    position: relative;
    margin-bottom: 75px;

    @include media($tablet-up) {
      height: 190px;
      width: 48%;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      margin-bottom: 0;
    }

    @include media($desktop-up) {
      height: 282px;
      width: 371px;
    }

    @include media($lg-desktop-up) {
      height: 356px;
      width: 471px;
    }

    &--copy {
      font-size: 70px;
      line-height: 80px;
      font-family: $playfair;
      font-weight: bold;
      text-align: center;
      color: $burnt-orange;

      @include media($desktop-up) {
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: 2;
        text-align: left;
        font-size: 85px;
        line-height: 95px;
      }

      @include media($lg-desktop-up) {
        font-size: 120px;
        line-height: 130px;
      }
    }

    &--image {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: -1;
      transform: translate(-50%,-50%);
      opacity: 0.3;

      @include media($desktop-up) {
        top: 0;
        left: 20px;
        transform: unset;
        opacity: 1;
      }

      svg path {
        fill: #F6EDEA;
      }
    }
  }
}