// Typography
$lato: 'Lato', sans-serif;
$playfair: 'Playfair Display', serif;
$base-font-family: $lato;
$heading-font-family: $base-font-family;

// Font Sizes
$base-font-size: 1em;

// Line height
$base-line-height: 1.2;
$heading-line-height: 1.2;

// Other Sizes
$base-border-radius: 0px;
$base-spacing: $base-line-height * 1em;
$small-spacing: $base-spacing / 2;
$base-z-index: 0;

// Colors
$blue: #477dca;
$white: #fff;
$dark-gray: #333;
$medium-gray: #777777;
$light-gray: #ddd;
$burnt-orange: #A94E30;
$brown: #675B50;
$cream: #FFE2C4;
$light-cream: #FFF3E4;

// Font Colors
$base-background-color: $white;
$base-font-color: $brown;
$action-color: $burnt-orange;

// Border
$base-border-color: $brown;
$base-border: 1px solid $base-border-color;

// Forms
$form-box-shadow: inset 0 1px 3px rgba(#000, 0.06);
$form-box-shadow-focus: $form-box-shadow, 0 0 5px adjust-color($action-color, $lightness: -5%, $alpha: -0.3);

// Buttons
$buttons-list: 'button',
               'input[type="button"]',
               'input[type="reset"]',
               'input[type="submit"]';

// Inputs
$text-inputs-list: 'input[type="color"]',
                   'input[type="date"]',
                   'input[type="datetime"]',
                   'input[type="datetime-local"]',
                   'input[type="email"]',
                   'input[type="month"]',
                   'input[type="number"]',
                   'input[type="password"]',
                   'input[type="search"]',
                   'input[type="tel"]',
                   'input[type="text"]',
                   'input[type="time"]',
                   'input[type="url"]',
                   'input[type="week"]',
                   'input:not([type])',
                   'textarea';
