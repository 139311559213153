// -- Image Text -- //

$ns: '.image-2up-component';

#{$ns} {
  position: relative;
  margin: 30px 0;

  @include media($tablet-up) {
    margin: 100px 0;
  }

  &__wrapper {
    max-width: 1056px;
    margin: 0 auto;

    @include media($tablet-up) {
      display: flex;
      justify-content: space-between;
    }
  }

  &__item {
    position: relative;
    transition: transform 0.5s ease-in-out;

    @include media($tablet-up) {
      width: 48%;
    }

    &:hover {
      @include media($desktop-up) {
        transform: translateY(-5%);

        &:before {
          opacity: 1;
        }

        img {
          box-shadow: 0 25px 60px 0 rgba(0,0,0,0.5);
        }

        #{$ns} {
          &__item--title {
            opacity: 1;
          }

        }
      }
    }

    &:before {
      content: '';
      background-image: linear-gradient(224deg, rgba(0,0,0,0.21) 0%, rgba(0,0,0,0.78) 100%);
      border-radius: 10px;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      border-radius: 10px;

      @include media($desktop-up) {
        opacity: 0;
        transition: opacity 0.5s ease-in-out;
      }
    }

    
    &--title {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      color: #fff;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;

      font-family: $playfair;
      font-weight: bold;
      font-size: 24px;
      line-height: 35px;
      margin-bottom: 25px;

      @include media($desktop-up) {
        opacity: 0;
        transition: opacity 0.5s ease-in-out;
      }
    }
  }

  &__image {
    margin-bottom: 30px;
    line-height: 0;

    @include media($tablet-up) {
      margin-bottom: 0;
    }

    img {
      border-radius: 10px;
      width: 100%;
      box-shadow: 0 25px 60px 0 rgba(0,0,0,0.10);
      transition: box-shadow 0.5s ease-in-out;
    }
  }

  &__text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    @include media($tablet-up) {
      padding: 50px;
      text-align: left;
    }

    @include media($lg-desktop-up) {
      padding: 100px;
    }
  }

  &.us-beach {
    #{$ns} {
      &__item--content {
        font-family: $playfair;
        font-weight: bold;
        font-size: 24px;
        line-height: 40px;
      }
    }
  }

  &.venue {
    z-index: 1;
  }
}