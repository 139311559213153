/*
 * SMACSS SCSS
 * Inspired by SMACSS http://smacss.com
 * Author: Justin Bond
 *
 * https://justin-bond.com
 *
 */

@import "normalize";

/* Utilities */
@import "utilities/functions";

/* Base */
@import "base/grid-settings";
@import "base/mixins";
@import "base/base";

/* Layout */
@import "layout/grid";
@import "layout/header";
@import "layout/footer";
@import "layout/sidebar";

/* Modules */
@import "modules/animations";
@import "modules/btn";
@import "modules/helpers";
@import "modules/icons";

/* Pages */
@import "pages/page";
@import "pages/home";

/* Compoents */
@import "components/header";
@import "components/hero";
// @import "components/hero-home";
@import "components/text-2up";
@import "components/text-3up";
@import "components/image-text";
@import "components/image-2up";
@import "components/text-block";
@import "components/rsvp";
