/* ==========================================================================
   Mixins
   ========================================================================== */

@mixin media($query: $feature $value $columns) {
  $media-query: "screen and ";

  @media #{$media-query + "(" + nth($query, 1) + ": " + nth($query, 2) + ") "} {
    @content;
  }
}
