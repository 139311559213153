/* ==========================================================================
   Animations
   ========================================================================== */

[data-animate] {
  transition: all 0.5s ease-in-out;
  opacity: 0;
  transform: translateY(50px);
}

[data-animate="true"] {
  opacity: 1;
  transform: translateY(0);
}