#{$buttons-list},
button {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-font-smoothing: antialiased;
  background-color: $white;
  border-radius: $base-border-radius;
  border: $base-border;
  color: $action-color;
  cursor: pointer;
  display: inline-block;
  font-family: $base-font-family;
  font-size: 18px;
  font-weight: 600;
  line-height: 1;
  padding: 13px 50px;
  text-decoration: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;

  @include media($desktop-up) {
    &:hover,
    &:focus {
      // background-color: darken($action-color, 15%);
      color: $white;
    }
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}
