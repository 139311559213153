// -- Hero -- //

$ns: '.hero-component';

#{$ns} {
  background-color: $burnt-orange;
  position: relative;
  overflow: hidden;

  &__wrapper {
    position: relative;
    width: 90%;
    max-width: 1056px;
    margin: 0 auto;
    height: 100vh;
    display: flex;
    align-items: center;

    @include media($mobile-up) {
      height: auto;
      padding: 170px 0;
      justify-content: center;
    }
  }

  &__text {
    z-index: 1;
    transition: opacity 0.5s ease-in-out;
    opacity: 0;

    @include media($desktop-up) {
      width: 38%;
      visibility: hidden;
    }

    &[data-hero-animate="true"] {
      visibility: visible;
      opacity: 1;
    }
  }

  &__title {
    font-size: 80px;
    line-height: 90px;
    letter-spacing: 2px;
    font-family: $playfair;
    font-weight: bold;
    color: $cream;
    margin-bottom: 30px;

    @include media($lg-desktop-up) {
      font-size: 90px;
      line-height: 100px;
    }
  }

  &__description {
    color: $cream;
    font-weight: bold;
    margin-bottom: 40px;
    letter-spacing: 2px;
    width: 75%;
    line-height: 26px;

    @include media($desktop-up) {
      width: 100%;
    }
  }

  &__button {
    margin-top: 60px;
  }

  &__image {
    position: relative;
    transition: all 0.5s ease-in-out;
    opacity: 0;
    visibility: hidden;
    transform: translate(50%, 0%);
    line-height: 0;
    display: none;

    @include media($desktop-up) {
      display: block;
      width: 62%;

      &:hover {
        .hero-component__image--text {
          visibility: visible;
          opacity: 1;
          right: -15px;
        }
      }
    }

    &:before {
      content:'';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: rgba(0,0,0,0.2);

      @include media($desktop-up) {
        display: none;
      }
    }

    &[data-hero-animate="true"] {
      transform: translate(0%, 0%);
      visibility: visible;
      opacity: 1;
    }

    img {
      width: 100%;
      box-shadow: 0 25px 60px 0 rgba(0,0,0,0.10);
      
      @include media($desktop-up) {
        border-radius: 10px;
      }

      @include media($desktop-down) {
        height: 85vh;
        object-fit: cover;
      }
    }

    &--text {
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 1px;
      z-index: -1;
      transition: all 0.5s ease-in-out;
      opacity: 0;
      visibility: hidden;
      padding-right: 25px;

      @include media($desktop-up) {
        position: absolute;
        right: 25px;
        top: 60px;
        bottom: 60px;
        transform: rotate(180deg);
        display: flex;
        align-items: center;
        justify-content: space-between;
        writing-mode: vertical-rl;
      }

      a {
        color: $white;
      }
    }
  }

  &__photo-cred {
    color: $cream;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;

    @include media($desktop-down) {
      position: absolute;
      bottom: 40px;
      width: 100%;
    }

    &:after {
      @include media($desktop-up) {
        content: '';
        height: 100px;
        width: 1px;
        margin-top: 22px;
        margin-left: 0;
        background-color: $cream;
      }
    }

    a {
      color: $cream;
    }

    svg {
      @include media($desktop-down) {
        margin-right: 7px;
        width: 27px;
        
        g {
          transform: translate(-6%, 0%) rotate(0deg);
        }
      }

      @include media($desktop-up) {
        margin-bottom: 7px;
      }

      path {
        fill: $cream;
      }
    }
  }

  &__floating-image {
    position: absolute;
    left: -90px;
    bottom: -15px;
    max-width: 290px;

    @include media($desktop-up) {
      left: -40px;
      bottom: -95px;
      max-width: inherit;
    }

    svg {
      width: 100%;
    }
  }

  &.home {
    #{$ns} {
      &__floating-image {
        svg {
          opacity: 0.2;
        }
      }
    }
  }

  &.details {
    background-color: #FFFAF3;
    
    #{$ns} {
      &__title,
      &__description {
        color: $burnt-orange;
      }

      &__floating-image {
        svg {
          path {
            fill: $cream !important;
          }
        }
      }
    }
  }
}