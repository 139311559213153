/* ==========================================================================
   Helpers
   ========================================================================== */
.clearfix {
  content: "";
  display: table;
  clear: both;
}
.position-relative {
  position: relative;
}
.hide--mobile{
  display:none;
  @include media($mobile-up){
    display:block;
  }
}
.hide--tablet{
  display:none;
  @include media($tablet-up){
    display:block;
  }
}
.hide--desktop{
  display:block;
  @include media($tablet-up){
    display:none;
  }
}
.float-right {
  float: right !important;
}
.float-left {
  float: left !important;
}
.underline{
  text-decoration:underline;
}
.uppercase{
  text-transform:uppercase;
}
.video-aspect{
  padding-bottom:56.25%;
  position:relative;
  iframe{
    top:0px;
    left:0px;
    width:100%;
    height:100%;
    position:absolute;
  }
}

.slick-slider{
  overflow:hidden;
}
.slick-track{
  width:100%;
}
.opacity--hover{
  opacity:1;
  -webkit-transition:opacity 0.2s linear;
  -moz-transition:opacity 0.2s linear;
  -o-transition:opacity 0.2s linear;
  transition:opacity 0.2s linear;
  &:hover{
    opacity:0.8;
  }
}
.entry-content {
  ul {
    list-style-type: disc;
    margin: 1em 0;
    padding-left: 40px;
  }
  ol {
    list-style-type: decimal;
    margin: 1em 0;
    padding-left: 40px;
  }
  img {
    width: auto;
    margin: 15px 0;
  }
  .alignright {
    float: right;
    margin-left: 15px;
  }
  .alignleft {
    float: left;
    margin-right: 15px;
  }
}

.link__underline {
  position: relative;
  &:after {
    content: "";
    position: absolute;
    bottom: -5px;
    width: 0px;
    height: 1px;
    margin: 5px 0 0;
    transition: all 0.5s ease-in-out;
    opacity: 0;
    left: 0;
    background-color: currentColor;
  }
  &:hover {
    &:after {
      width: 100%;
      opacity: 1;
    }
  }
}